<template>
  <ckeditor
    @input="input"
    :value="value"
    :editor="editor"
    :config="editorConfig"
  ></ckeditor>
</template>

<script>
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export default {
  name: 'TextEditor',
  props: {
    value: {
      type: String,
      default: '',
    },
  },
  methods: {
    input(newValue) {
      this.$emit('input', newValue);
    },
  },
  data() {
    return {
      editor: ClassicEditor,
      editorConfig: {
        toolbar: [
          'heading',
          '|',
          'bold',
          'italic',
          'link',
          '|',
          'bulletedList',
          'numberedList',
          'blockQuote',
        ],
      },
    };
  },
};
</script>
